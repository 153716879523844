import React from "react";
import PropTypes from "prop-types";
import Zoom from "@material-ui/core/Zoom";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

// Implementation

/* <ScrollTop {...props}>
<Fab color="secondary" size="small" aria-label="scroll back to top">
  <KeyboardArrowUpIcon />
</Fab>
</ScrollTop> */

function ScrollTop(props) {
  const { children } = props;
  let window = undefined;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  const trigger = useScrollTrigger({
    target: window ? document.window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    window = global || window;
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // const anchor = event.target.ownerDocument || document.querySelector("#top-nav");

    // debugger;
    // if (anchor) {
    //   debugger;
    //   anchor.scrollIntoView({ behavior: "smooth", block: "top" });
    // }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

ScrollTop.propTypes = { children: PropTypes.object };

export default ScrollTop;
